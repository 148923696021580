import { LoadingOutlined } from '@ant-design/icons';
import { FC } from 'react';
import useTossOrder from 'src/hooks/useTossOrder';
import { errorObjectToString } from 'src/lib/1/util';
import { TossOrder } from 'src/schema/schema-toss-order';

interface TossOrderItemProps {
	orderId: string;
	field: keyof TossOrder;
}

const TossOrderItem: FC<TossOrderItemProps> = ({ orderId, field }) => {
	const { tossOrder, isLoading, error } = useTossOrder(orderId);

	if (isLoading) {
		return <LoadingOutlined />;
	}

	if (error) {
		return <span style={{ color: 'var(--red700)' }}>{errorObjectToString(error)}</span>;
	}

	if (!tossOrder) {
		return null;
	}

	return <span>{tossOrder[field]}</span>;
};

export default TossOrderItem;
