import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { v4 } from 'uuid';

export const isDevelopment = process.env.NODE_ENV === 'development';

export const sessionId = v4();

export const sleep = async (ms?: number) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

export const formatNumber = (value: number) => Intl.NumberFormat().format(value);

export const formatDateForKR = (date: string) => {
	return format(new Date(date), 'dd(EEEEEE)', { locale: ko });
};

/**
 * - 를 삽입한다.
 */
export function normalizeTel(telNo?: string) {
	if (telNo == null || telNo === '') {
		return '';
	}
	// 숫자 이외에는 모두 제외한다.
	telNo = telNo.replace(/[^0-9]/g, '');

	// 2018-11-15 부터는 050으로 변환해서 FS에 저장하기 때문에 불펼요할 수 있다.
	telNo = telNo.replace(/^090/, '050');

	// 010- , 070-
	let matches = telNo.match(/^(0[17][01])(.{3,4})(.{4})$/);
	if (matches) {
		return `${matches[1]}-${matches[2]}-${matches[3]}`;
	}

	// 050은 4자리 식별번호를 사용하지만 3자리가 익숙하니 12자리가 아닌 경우에는 050에서 끊어준다.
	// 050-AAA?-BBBB
	matches = telNo.match(/^(050)(.{3,4})(.{4})$/);
	if (matches) {
		return `${matches[1]}-${matches[2]}-${matches[3]}`;
	}

	// 050X-AAAA-BBBB
	matches = telNo.match(/^(050.)(.{4})(.{4})$/);
	if (matches) {
		return `${matches[1]}-${matches[2]}-${matches[3]}`;
	}

	matches = telNo.match(/^(02)(.{3,4})(.{4})$/);
	if (matches) {
		return `${matches[1]}-${matches[2]}-${matches[3]}`;
	}

	matches = telNo.match(/^(0..)(.{3,4})(.{4})$/);
	if (matches) {
		return `${matches[1]}-${matches[2]}-${matches[3]}`;
	}

	return telNo;
}

export const filterInt = (value: string) => {
	if (/^[-+]?(\d+|Infinity)$/.test(value)) {
		return Number(value);
	} else {
		return NaN;
	}
};

export const getDeliveryDate = (days: number, orderDeliveryDate?: string, orderDate?: Date) => {
	const now = orderDate ?? new Date();
	return orderDeliveryDate ?? format(new Date(now.setDate(now.getDate() + days)), "yyyy-MM-dd'T'08:00:00+0900");
};

const matchOperatorsRe = /[|\\{}()[\]^$+*?.]/g;
export const escapeStringRegExp = (str: string) => {
	return new RegExp(str.replace(matchOperatorsRe, '\\$&'));
};

/**
 * 값이 undefined인 경우에 대한 처리를 추가한 JSON.parse
 */
export function jsonParseWithCatch<T>(value: string | null): T | undefined {
	try {
		return value === 'undefined' ? undefined : JSON.parse(value ?? 'null');
	} catch (error) {
		console.error(error);
		return undefined;
	}
}

export function setLocalStorageValue<T>(key: string, newValue: T) {
	localStorage.setItem(key, JSON.stringify(newValue));
}

export function getLocalStorageValue<T>(key: string) {
	const storedLocalValue = jsonParseWithCatch<T>(localStorage.getItem(key));
	return storedLocalValue;
}

export async function getPublicIp() {
	try {
		const response = await fetch('https://api.ipify.org?format=json', {
			cache: 'no-cache',
		});
		if (response.ok) {
			const body = await response.json();
			return body.ip as string;
		} else {
			return 'unknown';
		}
	} catch (error) {
		console.error('[getPublicIp]', error);
		return 'error';
	}
}

export const getLastObject = (obj: any) => {
	const keys = Object.keys(obj);
	return obj[keys[keys.length - 1]];
};

export const textSort = (a: string, b: string) => {
	return a < b ? -1 : a > b ? 1 : 0;
};

export const parseIfObject = (value: any) => {
	if (typeof value === 'object') {
		return JSON.stringify(value);
	}
	return value;
};

/**
 * @param date Date
 * @returns '2022-02-06T20:00:00+0900'
 */
export const dateFormat01 = (date?: Date) => format(date ? new Date(date) : new Date(), "yyyy-MM-dd'T'HH:mm:ss+0900");

export const errorObjectToString = (error: any) => (error instanceof Error ? error.message : parseIfObject(error));
