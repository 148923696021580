import { AuditOutlined, CreditCardOutlined } from '@ant-design/icons';
import { FC } from 'react';
import Loading from './components/Loading/Loading';
import { useAuth } from './stores/auth-context';
import { Navigate, Route, Routes } from 'react-router-dom';
import SigninPage from './pages/SigninPage';
import CoursePage from './pages/CoursePage';
import AppLayout from './components/Layout/AppLayout';
import ClassroomPage from './pages/ClassroomPage';
import PaymentPage from './pages/PaymentPage';

export const siteMap: Record<
	string,
	{
		title: string;
		page: FC<any>;
		icon?: React.ReactNode;
		path: string;
		children?: any[];
	}
> = {
	course: {
		title: '계정 관리',
		path: 'course',
		page: CoursePage,
		icon: <AuditOutlined />,
	},
	payment: {
		title: '결제 관리',
		path: 'payment',
		page: PaymentPage,
		icon: <CreditCardOutlined />,
		children: [
			{
				label: '결제 생성',
				icon: null,
				key: 'payment/product',
			},
			{
				label: '결제 내역',
				icon: null,
				key: 'payment/history',
			},
		],
	},
	login: { title: '로그인', path: 'login', page: SigninPage, icon: undefined },
};

const RouteElements = () => (
	<>
		{Object.values(siteMap)
			.filter((page) => page.icon !== undefined)
			.map((site) => (
				<Route key={site.path} path={`/${site.path}/*`} element={<site.page title={site.title} />} />
			))}
	</>
);

const Router: FC = () => {
	const { authUser, loading: authLoading, isLoggedIn } = useAuth();
	const initialized = authUser !== null;

	return (
		<>
			{authLoading && <Loading title="잠시만 기다려주세요." />}
			<AppLayout>
				<Routes>
					<Route path="/" element={<Navigate to={isLoggedIn ? '/course' : '/login'} replace />} />
					{!isLoggedIn && <Route path="/login" element={initialized ? <SigninPage title="로그인" /> : null} />}
					{isLoggedIn && RouteElements()}
					{isLoggedIn && <Route path="/classroom/:classroomId" element={<ClassroomPage title="적용 강의" />} />}
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</AppLayout>
		</>
	);
};

export default Router;
