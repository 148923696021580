import { Button, notification } from 'antd';
import { useState } from 'react';
import { createProduct } from 'src/lib/3/firebase-short-cut';

const CreateProductButton = () => {
	const [loading, setLoading] = useState(false);

	const onClick = async () => {
		setLoading(true);
		try {
			await createProduct({
				name: '신규 상품',
				description: '신규 상품 설명',
				price: 100000,
			});
		} catch (error) {
			console.error(error);
			notification.error({
				message: '상품 생성에 실패했습니다.',
			});
		}
		setLoading(false);
	};

	return (
		<Button onClick={onClick} loading={loading}>
			신규 상품 생성
		</Button>
	);
};

export default CreateProductButton;
