import { Button, Dropdown, Layout, MenuProps, message } from 'antd';
import { FC, PropsWithChildren, useMemo } from 'react';
import useMediaQuery from 'src/hooks/useMediaQuery';

import { FirebaseManager } from 'src/lib/2/firebase-manager';

import { useAuth } from '../../stores/auth-context';
import classes from './AppLayout.module.scss';
import MainNavigation from './MainNavigation';

const { Content, Footer } = Layout;

const firebaseManager = FirebaseManager.getInstance();

const sendPasswordResetEmail = async (email?: string) => {
	if (!email) {
		message.error('이메일 정보가 없습니다.');
		return;
	}
	await firebaseManager.sendPasswordResetEmail(email);
	message.info('비밀번호 변경 이메일이 발송되었습니다.');
};

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
	const isDesktop = useMediaQuery();
	const { authUser, signout, user } = useAuth();

	const items: MenuProps['items'] = useMemo(() => {
		return authUser
			? [
					{
						key: '1',
						label: (
							<Button type="text" onClick={() => sendPasswordResetEmail(authUser.email ?? undefined)}>
								비밀번호 변경
							</Button>
						),
					},
					{
						key: '2',
						label: (
							<Button type="text" onClick={signout}>
								로그아웃
							</Button>
						),
					},
			  ]
			: [];
	}, [authUser, signout]);

	return (
		<Layout style={{ height: '100vh' }} className={classes.appLayout}>
			{authUser && <MainNavigation />}
			<Layout>
				<header className={classes.pageHeader}>
					<p className={classes.pageTitle}>{authUser ? '' : '로그인이 필요합니다.'}</p>
					{user && (
						<div className={classes.extra}>
							<Dropdown key={'loginMenu'} menu={{ items }} placement="bottomLeft">
								<span style={{ cursor: 'pointer' }}>
									{isDesktop ? user.email : user.email.split('@')?.[0] ?? 'user'}
								</span>
							</Dropdown>
						</div>
					)}
				</header>
				<Content style={{ padding: '20px', flex: 1 }}>
					<div className={classes.contentContainer}>{children}</div>
				</Content>
				<Footer
					style={{
						textAlign: 'center',
						backgroundColor: '#333d4b',
						color: 'white',
						padding: isDesktop ? '20px 50px' : '10px',
					}}
				>
					ⓒ 누나영 어학연구소
				</Footer>
			</Layout>
		</Layout>
	);
};

export default AppLayout;
