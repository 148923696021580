import { NewValueParams, ValueSetterParams } from 'ag-grid-community';
import { message } from 'antd';
import { Timestamp } from 'firebase/firestore';
import { get, has, set } from 'lodash-es';

import { orderDateFormat02 } from '../1/date-util';
import { FirebaseManager } from '../2/firebase-manager';

const firebaseManager = FirebaseManager.getInstance();

export const timestampFormatter = (params: any) => {
  const timestamp = params.value as Timestamp;
  return timestamp ? orderDateFormat02(timestamp.toDate().toISOString()) : '';
};

export const defaultValueGetter = (params: any) => {
  const value = (params.colDef.field as string)
    ?.split('.')
    ?.reduce((acc, cur) => acc?.[cur], params.data);
  return value ? value : 'unknown';
};

export const unknownValueCellClass = (params: any) => {
  return params.value === 'unknown' ? 'unknown' : '';
};

// TODO: options로 바꾸자
/**
 *
 * @param requiredFields data에서 참조해야하는 필드 path ex) ['a', 'b.c', 'd.e.f']
 * @param validation 추가로 해야하는 검증
 * @returns
 */
export function onValueSetterWithValidation<T extends object>(
  params: ValueSetterParams<T>,
  requiredFields?: string[],
  validation?: (value: any) => boolean,
  options?: {
    type?: 'number' | 'array' | 'boolean';
  }
) {
  const { type = 'string' } = options ?? {};
  const data: T | undefined = params.data;

  if (!data) {
    return false;
  }

  if (params.newValue === undefined || params.newValue === null) {
    message.error('값을 입력해주세요.');
    return false;
  }

  if (validation && !validation(params.newValue)) {
    return false;
  }

  if (requiredFields && requiredFields.length > 0) {
    for (const field of requiredFields) {
      if (!has(data, field)) {
        message.error(
          `필수 필드가 누락되었습니다. field: ${
            field as string
          }, 개발자에게 알려주세요.`
        );
        return false;
      }
    }
  }

  if (type === 'number') {
    set(params.data, params.colDef.field as string, Number(params.newValue));
  } else if (type === 'array') {
    const newValue = params.newValue
      .split(',')
      .map((v: string) => v.trim())
      .filter((v: string) => v.length > 0);
    set(params.data, params.colDef.field as string, newValue);
  } else {
    set(params.data, params.colDef.field as string, params.newValue);
  }
  return true;
}

/**
 * @param paths field path가 직접적인 경로가 아닌 경우 따로 입력한다.
 */
export async function onCellValueChangedWithUpdate<T>(
  params: NewValueParams<T>,
  collection: string,
  docId?: string,
  paths?: string
) {
  const field = params.colDef.field;
  if (!field) {
    message.error('수정할 수 없는 필드정보입니다.');
    return;
  }

  if (!docId) {
    message.error('id값이 없습니다.');
    return;
  }

  const newValue = get(params.data, field);
  if (
    newValue !== undefined &&
    newValue !== null &&
    newValue !== params.oldValue
  ) {
    try {
      await firebaseManager.updateDoc(collection, docId, {
        [paths ?? field]: newValue,
      });
      message.success('변경완료');
    } catch (error: any) {
      console.error(error);
      message.error(error?.message ?? '알 수 없는 에러 발생!');
    }
  }
}
