import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo, useState } from 'react';
import { tossPaymentsTransactionStatusKr } from 'src/lib/1/constant';
import { formatDate } from 'src/lib/1/date-util';
import { formatNumber } from 'src/lib/1/util';
import { TossPaymentsTransaction } from 'src/schema/schema-toss-payments';
import TossOrderItem from './TossOrderItem';
// import CancelTransactionCellRenderer from './CancelTransactionCellRenderer';

interface PaymentHistoryTableProps {
	rowData: TossPaymentsTransaction[] | undefined;
}

const defaultColDef: ColDef<TossPaymentsTransaction> = {
	flex: 1,
	sortable: true,
	resizable: true,
	filter: true,
};

const PaymentHistoryTable: FC<PaymentHistoryTableProps> = ({ rowData }) => {
	const [, setGridRef] = useState<AgGridReact<TossPaymentsTransaction> | null>(null);

	const columnDefs: ColDef<TossPaymentsTransaction>[] = useMemo(() => {
		return [
			{
				headerName: '결제시점',
				field: 'transactionAt',
				valueFormatter: (params) => {
					return params.value ? formatDate(new Date(params.value), 'L월 d일(EEEEEE) HH:mm:ss') : '';
				},
			},
			{
				field: 'mId',
				hide: true,
			},
			{
				headerName: '결제 키',
				field: 'paymentKey',
				hide: true,
			},
			{
				headerName: '거래번호',
				field: 'transactionKey',
			},
			{
				headerName: '결제금액',
				field: 'amount',
				// type: 'rightAligned',
				valueFormatter: (params) => {
					const transaction = params.data as TossPaymentsTransaction;
					const currency = transaction.currency === 'KRW' ? '원' : transaction.currency;
					return params.value ? `${formatNumber(params.value)}${currency}` : '';
				},
			},
			{
				headerName: '결제상태',
				field: 'status',
				valueGetter: (params: any) => {
					const transaction = params.data as TossPaymentsTransaction;
					return tossPaymentsTransactionStatusKr[transaction.status] ?? '⛔️ ERROR';
				},
			},
			{
				headerName: '결제통화',
				field: 'currency',
				hide: true,
			},
			{
				headerName: '고객 식별자',
				field: 'customerKey',
				hide: true,
			},
			{
				headerName: '고객명',
				field: 'orderId',
				cellRenderer: (params: any) => {
					const orderId = params.value as string;
					return <TossOrderItem orderId={orderId} field="customerName" />;
				},
			},
			{
				headerName: '고객 이메일',
				field: 'orderId',
				cellRenderer: (params: any) => {
					const orderId = params.value as string;
					return <TossOrderItem orderId={orderId} field="customerEmail" />;
				},
			},
			{
				headerName: '구매 상품',
				field: 'orderId',
				cellRenderer: (params: any) => {
					const orderId = params.value as string;
					return <TossOrderItem orderId={orderId} field="orderName" />;
				},
			},
			{
				headerName: '에스크로',
				field: 'useEscrow',
				hide: true,
			},
			{
				headerName: '결제수단',
				field: 'method',
			},
			{
				headerName: '주문번호',
				field: 'orderId',
			},
			{
				headerName: '영수증',
				maxWidth: 120,
				field: 'receiptUrl',
				cellRenderer: (params: any) => {
					const receiptUrl = params.value as string;
					return receiptUrl ? (
						<a href={receiptUrl} target="_blank" rel="noreferrer">
							영수증
						</a>
					) : (
						''
					);
				},
			},
			// {
			// 	headerName: '결제 취소',
			// 	maxWidth: 120,
			// 	cellRenderer: (params: any) => {
			// 		const transaction = params.data as TossPaymentsTransaction;
			// 		return (
			// 			<CancelTransactionCellRenderer paymentKey={transaction.paymentKey} IdempotencyKey={transaction.orderId} />
			// 		);
			// 	},
			// },
		];
	}, []);

	return (
		<div className="ag-theme-alpine height100">
			<AgGridReact
				ref={setGridRef}
				rowData={rowData}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				// rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
				getRowId={(params) => params.data.transactionKey}
				// 편집 완료후 스크롤 이동을 막는다.
				suppressScrollOnNewData={true}
			/>
		</div>
	);
};

export default PaymentHistoryTable;
