import { getTransactions } from 'src/lib/toss-payments-api';
import useSWR from 'swr';

const useTossPaymentsTransactions = (startDate: string, endDate: string) => {
	const {
		data: transactions,
		isLoading,
		error,
	} = useSWR(`transactions?startDate=${startDate}&endDate=${endDate}`, getTransactions);

	return {
		transactions,
		isLoading,
		error,
	};
};

export default useTossPaymentsTransactions;
