import { FC } from 'react';

import { useTitle } from '../hooks/useTitle';
import Payment from 'src/components/Payment/Payment';
import { Route, Routes } from 'react-router-dom';
import PaymentHistory from 'src/components/Payment/PaymentHistory/PaymentHistory';
import PaymentProduct from 'src/components/Payment/PaymentProduct/PaymentProduct';

const PaymentPage: FC<{ title: string }> = ({ title }) => {
	useTitle(`Play NNY 관리자 | ${title}`);
	return (
		<Routes>
			<Route path="/product" element={<PaymentProduct />} />
			<Route path="/history" element={<PaymentHistory />} />
			<Route path="/" element={<Payment />} />
		</Routes>
	);
};

export default PaymentPage;
