import axios from 'axios';
import { tossPaymentsSecretKey } from './1/constant';
import {
	TossPaymentsCancelTransactionResponse,
	TossPaymentsTransactionsResponse,
} from 'src/schema/schema-toss-payments';

const apiUrl = 'https://api.tosspayments.com/v1';

/**
 * @param path `transactions?startDate=${startDate}&endDate=${endDate}`
 */
export async function getTransactions(path: string) {
	try {
		const response = await axios.get<TossPaymentsTransactionsResponse>(`${apiUrl}/${path}`, {
			headers: {
				Authorization: `Basic ${btoa(`${tossPaymentsSecretKey}:`)}`,
			},
		});
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

export async function cancelTransaction(path: string, IdempotencyKey: string, cancelReason = '관리자 취소') {
	try {
		const response = await axios.post<TossPaymentsCancelTransactionResponse>(
			`${apiUrl}/${path}`,
			{
				cancelReason,
			},
			{
				headers: {
					Authorization: `Basic ${btoa(`${tossPaymentsSecretKey}:`)}`,
					'Idempotency-Key': IdempotencyKey,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}
