import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo, useState } from 'react';
import { formatNumber } from 'src/lib/1/util';
import { onCellValueChangedWithUpdate, onValueSetterWithValidation } from 'src/lib/3/ag-grid-util';
import { ProductDoc } from 'src/schema/schema-product';
import DeleteProductButton from './DeleteProductButton';

interface PaymentProductTableProps {
	rowData: ProductDoc[];
}

const defaultColDef: ColDef<ProductDoc> = {
	flex: 1,
	sortable: true,
	resizable: true,
	filter: true,
};

const PaymentProductTable: FC<PaymentProductTableProps> = ({ rowData }) => {
	const [, setGridRef] = useState<AgGridReact<ProductDoc> | null>(null);

	const columnDefs: ColDef<ProductDoc>[] = useMemo(() => {
		return [
			{
				headerName: '상품ID',
				field: '_id',
				hide: true,
			},
			{
				headerName: '상품명',
				field: 'name',
				editable: true,
				valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
				onCellValueChanged: (params) => onCellValueChangedWithUpdate(params, 'product', params.data._id),
			},
			{
				headerName: '상품설명',
				field: 'description',
				editable: true,
				valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
				onCellValueChanged: (params) => onCellValueChangedWithUpdate(params, 'product', params.data._id),
			},
			{
				headerName: '상품가격',
				field: 'price',
				editable: true,
				valueFormatter: (params) => formatNumber(params.value ?? 0) + '원',
				valueSetter: (params) =>
					onValueSetterWithValidation(params, ['_id'], undefined, {
						type: 'number',
					}),
				onCellValueChanged: (params) => onCellValueChangedWithUpdate(params, 'product', params.data._id),
			},
			{
				headerName: '결제링크',
				field: '_id',
				cellRenderer: (params: any) => {
					const _id = params.value as string;
					const paymentUrl = `https://playnny.com/anonymous-order/${_id}`;
					return _id ? (
						<a href={paymentUrl} target="_blank" rel="noreferrer">
							결제 링크
						</a>
					) : (
						''
					);
				},
			},
			{
				headerName: '링크 삭제',
				field: '_id',
				cellRenderer: (params: any) => {
					const _id = params.value as string;
					return <DeleteProductButton id={_id} />;
				},
			},
		];
	}, []);
	return (
		<div className="ag-theme-alpine height100">
			<AgGridReact
				ref={setGridRef}
				rowData={rowData}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				// rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
				getRowId={(params) => params.data._id}
				// 편집 완료후 스크롤 이동을 막는다.
				suppressScrollOnNewData={true}
			/>
		</div>
	);
};

export default PaymentProductTable;
