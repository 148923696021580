import { getTossOrder } from 'src/lib/3/firebase-short-cut';
import useSWR from 'swr';

const useTossOrder = (orderId: string) => {
	const {
		data: tossOrder,
		isLoading,
		error,
	} = useSWR(orderId, getTossOrder, {
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
		errorRetryCount: 1,
	});

	return {
		tossOrder,
		isLoading,
		error,
	};
};

export default useTossOrder;
