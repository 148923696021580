import { FC, useMemo, useState } from 'react';
import TableOptions from 'src/components/Common/TableOptions/TableOptions';
import useTossPaymentsTransactions from 'src/hooks/useTossPaymentsTransactions';
import { RangeEventValue, dayjsesToTossPaymentsDates, getDefaultDates } from 'src/lib/1/date-util';
import PaymentHistoryTable from './PaymentHistoryTable';

const defaultValue = getDefaultDates(3, 1);

const PaymentHistory: FC = () => {
	const [dates, setDates] = useState<RangeEventValue>(null);
	const [startDate, endDate] = useMemo(() => {
		return dates?.[0] && dates?.[1]
			? dayjsesToTossPaymentsDates(dates[0], dates[1])
			: dayjsesToTossPaymentsDates(defaultValue[0], defaultValue[1]);
	}, [dates]);

	const { transactions } = useTossPaymentsTransactions(startDate, endDate);

	return (
		<div className="height100 flexColumn">
			<TableOptions setDates={setDates} defaultValue={defaultValue} />
			<PaymentHistoryTable rowData={transactions} />
		</div>
	);
};

export default PaymentHistory;
