import { Button, Form, Input, Modal } from 'antd';
import { FC, useEffect, useState } from 'react';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { PlayLecture } from 'src/lib/1/schema';
import { createPlayLecture, getLastPlayLectureNumberForClassroom } from 'src/lib/3/firebase-short-cut';
import { useAuth } from 'src/stores/auth-context';

const regex = /\/video\/([0-9]+)/;

type LectureFormProps = {
	classroomId: string;
};

const LectureForm: FC<LectureFormProps> = ({ classroomId }) => {
	const isDesktop = useMediaQuery();
	const [open, setOpen] = useState(false);
	const { setLoading } = useAuth();
	const [form] = Form.useForm<PlayLecture>();

	const onFinish = async (values: PlayLecture) => {
		setLoading(true);
		const number = await getLastPlayLectureNumberForClassroom(classroomId);
		const matches = values.embedUrl.match(regex);
		const vimeoUrl = matches?.[1] ? `/videos/${matches[1]}` : null;
		const lecture: PlayLecture = {
			name: values.name.trim(),
			embedUrl: values.embedUrl,
			vimeoUrl,
			description: values.description && values.description?.length > 0 ? values.description : null,
			hide: false,
			number,
			classroomId,
		};
		await createPlayLecture(lecture);
		setLoading(false);
	};

	const FormBox = (
		<Form
			form={form}
			name="horizontal_login"
			layout={isDesktop ? 'inline' : 'horizontal'}
			onFinish={onFinish}
			style={{ marginBottom: 20 }}
		>
			<Form.Item
				name="name"
				label={isDesktop ? undefined : '강의 영상 제목'}
				rules={[{ required: true, message: '제목을 입력해주세요.' }]}
			>
				<Input placeholder="*강의 영상 제목" />
			</Form.Item>
			<Form.Item
				name="embedUrl"
				label={isDesktop ? undefined : '강의 영상 주소'}
				rules={[{ required: true, message: '영상 주소를 입력해주세요.' }]}
			>
				<Input placeholder="*강의 영상 주소" />
			</Form.Item>
			<Form.Item name="description" label={isDesktop ? undefined : '강의 설명'}>
				<Input placeholder="강의 설명" />
			</Form.Item>

			<Form.Item shouldUpdate>
				{() => (
					<Button type="primary" htmlType="submit">
						강의 추가
					</Button>
				)}
			</Form.Item>
			{isDesktop && (
				<Form.Item>
					<Button onClick={() => form.resetFields()}>Reset</Button>
				</Form.Item>
			)}
		</Form>
	);

	useEffect(() => {
		if (!open) form.resetFields();
	}, [form, open]);

	return (
		<>
			{isDesktop ? (
				FormBox
			) : (
				<>
					<Button style={{ marginBottom: 10 }} onClick={() => setOpen(true)}>
						{open ? '닫기' : '강의 추가'}
					</Button>
					<Modal title="강의 추가" open={open} footer={null} onCancel={() => setOpen(false)}>
						{FormBox}
					</Modal>
				</>
			)}
		</>
	);
};

export default LectureForm;
