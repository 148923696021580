import useSWR from 'swr';
import { PlayClassroomDoc } from 'src/lib/1/schema';
import { FirebaseManager } from 'src/lib/2/firebase-manager';

const firebaseManager = FirebaseManager.getInstance();
const playClassroomPath = 'playClassroom';

const getClassroomList = () => {
	return firebaseManager.getDocsArrayWithWhere<PlayClassroomDoc>(playClassroomPath, []);
};

const useClassroomList = () => {
	const { data: classroomList, isLoading } = useSWR('classroomList', getClassroomList);
	return {
		classroomList: (classroomList ?? []).filter((doc) => !doc._timeDelete).sort((a, b) => a.number - b.number),
		isLoading,
	};
};

export default useClassroomList;
