import { message } from 'antd';
import { User, UserCredential } from 'firebase/auth';
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  PropsWithChildren,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { UserDoc } from '../lib/1/schema';
import { FirebaseManager } from '../lib/2/firebase-manager';

type AuthUser = {
  uid: string;
  email: string | null;
  name: string | null;
  provider: string;
  photoUrl: string | null;
  stripeRole: string | object;
  token: string;
};

interface AuthContext {
  user?: UserDoc;
  authUser: false | AuthUser | null;
  loading: boolean;
  isLoggedIn: boolean;
  createUserWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<UserCredential>;
  signinWithEmail: (email: string, password: string) => Promise<UserCredential>;
  signout: () => Promise<void>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const authContext = createContext<AuthContext | null>(null);
const firebaseManager = FirebaseManager.getInstance();
const userPath = 'user';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(authContext);
  if (context == null) {
    throw new Error('Out of context: authProvider is not set');
  }
  return context;
};

const useProvideAuth = () => {
  const navigate = useNavigate();
  // 1. 최초 진입시 유저 정보는 null이다.
  const [authUser, setAuthUser] = useState<AuthUser | null | false>(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserDoc>();
  const isLoggedIn = useMemo(() => !!user?._id, [user?._id]);
  const authUserId = useMemo(() => authUser && authUser.email, [authUser]);

  const createUserWithEmailAndPassword = (email: string, password: string) => {
    setLoading(true);
    return firebaseManager.createUserWithEmailAndPassword(email, password);
  };

  const signinWithEmail = (email: string, password: string) => {
    setLoading(true);
    return firebaseManager.signIn(email, password);
  };

  const signout = useCallback(() => {
    navigate('/');
    return firebaseManager.signOut();
  }, [navigate]);

  /**
   * 로그인 상태 변화에 따라 유저 정보에 변동이 있으면
   * 해당 내용에 따른 authUser의 상태를 업데이트한다.
   */
  const handleUser = useCallback(async (authUser: User | null) => {
    try {
      setLoading(true);
      if (authUser) {
        const user = await formatUser(authUser);
        const { email } = user;
        if (email === null) {
          throw new Error('email is null');
        }
        setAuthUser(user);
        setLoading(false);
        return user;
      }
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error('알 수 없는 에러 발생');
      }
    }

    // 위에서 return에 실패한 경우
    setAuthUser(false);
    setLoading(false);
    return false;
  }, []);

  // 최초 앱 실행후 auth를 Provide하는 순간부터
  // 하위 컴포넌트를 기준으로
  // 로그인 상태변화는 계속 관찰상태에 놓인다.
  useEffect(() => {
    const subscription = firebaseManager
      .observeAuthState()
      .subscribe(handleUser);
    return () => subscription.unsubscribe();
  }, [handleUser]);

  useEffect(() => {
    if (authUserId) {
      const subscription = firebaseManager
        .observeDoc<UserDoc>(`${userPath}/${authUserId}`)
        .subscribe(async (user0) => {
          const isAuthorized = ['manager', 'admin'].includes(user0.role);
          if (!isAuthorized) {
            message.error('사용권한이 없습니다. 로그아웃 합니다.');
            await firebaseManager.signOut();
            return;
          }
          setUser(user0);
        });

      return () => {
        setUser(undefined);
        subscription.unsubscribe();
      };
    } else {
      setUser(undefined);
    }
  }, [authUserId]);

  return {
    user,
    authUser,
    loading,
    isLoggedIn,
    createUserWithEmailAndPassword,
    signinWithEmail,
    signout,
    setLoading,
  };
};

const getStripeRole = async () => {
  await firebaseManager.getCurrentUser()?.getIdToken(true);
  const decodedToken = await firebaseManager
    .getCurrentUser()
    ?.getIdTokenResult();

  return decodedToken?.claims.stripeRole || 'free';
};

const formatUser = async (user: User): Promise<AuthUser> => {
  const token = await user.getIdToken();
  return {
    uid: user.uid,
    email: user.email,
    name: user.displayName,
    provider: user.providerData[0].providerId,
    photoUrl: user.photoURL,
    stripeRole: await getStripeRole(),
    token,
  };
};
