import { Select } from 'antd';
import { FC, useCallback } from 'react';
import useClassroomList from 'src/hooks/useClassroomList';
import { PlayCourse } from 'src/lib/1/schema';
import { updateCourseClassrooms } from 'src/lib/3/firebase-short-cut';

const ClassroomSelector: FC = (params: any) => {
	const { classroomList } = useClassroomList();
	const classrooms = params.value as PlayCourse['classrooms'];
	const value = classrooms?.map((v) => v.classroomId);
	const options = classroomList.map((item) => ({
		label: item.name,
		value: item._id,
	}));

	const handleOnChange = useCallback(
		async (courseId: string, value: string[]) => {
			const classrooms = classroomList
				.filter((classroom) => value.includes(classroom._id))
				.map((classroom) => ({
					number: classroom.number,
					name: classroom.name,
					classroomId: classroom._id,
				}));
			await updateCourseClassrooms(courseId, classrooms);
		},
		[classroomList]
	);

	return (
		<Select
			mode="multiple"
			placeholder="강의실 입력 또는 선택."
			optionFilterProp="label"
			defaultValue={value}
			onChange={(value) => handleOnChange(params.data._id, value)}
			style={{ width: '100%' }}
			options={options}
		/>
	);
};

export default ClassroomSelector;
