import { FC, useEffect, useState } from 'react';
import { PlayLectureDoc } from 'src/lib/1/schema';
import { subscribePlayLectureForClassrooom } from 'src/lib/3/firebase-short-cut';
import LectureForm from './LectureForm/LectureForm';
import ClassroomTable from './ClassroomTable/ClassroomTable';

type ClassroomProps = {
	classroomId: string;
};

const Classroom: FC<ClassroomProps> = ({ classroomId }) => {
	const [rowData, setRowData] = useState<PlayLectureDoc[]>([]);

	useEffect(() => {
		if (classroomId) {
			const subscription = subscribePlayLectureForClassrooom(classroomId).subscribe(setRowData);
			return () => subscription.unsubscribe();
		}
	}, [classroomId]);

	return (
		<div className="height100 flexColumn">
			<LectureForm classroomId={classroomId} />
			<ClassroomTable rowData={rowData} />
		</div>
	);
};

export default Classroom;
