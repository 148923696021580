import { Button, Popconfirm, notification } from 'antd';
import { FC, useCallback, useState } from 'react';
import { errorObjectToString } from 'src/lib/1/util';
import { deleteProduct } from 'src/lib/3/firebase-short-cut';

interface DeleteProductButtonProps {
	id: string;
}

const DeleteProductButton: FC<DeleteProductButtonProps> = ({ id }) => {
	const [loading, setLoading] = useState(false);

	const onDeleteProduct = useCallback(async () => {
		setLoading(true);
		try {
			await deleteProduct(id);
			notification.success({
				message: '결제 링크 삭제 성공',
			});
		} catch (error) {
			const description = errorObjectToString(error);
			notification.error({
				message: '결제 링크 삭제 실패',
				description,
			});
		}
		setLoading(false);
	}, [id]);

	return (
		<Popconfirm title="정말로 삭제하시겠습니까?" onConfirm={onDeleteProduct} okButtonProps={{ loading }}>
			<Button danger>삭제</Button>
		</Popconfirm>
	);
};

export default DeleteProductButton;
