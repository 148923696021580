import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';

import { LinkOutlined } from '@ant-design/icons';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo, useState } from 'react';
import { PlayLectureDoc } from 'src/lib/1/schema';
import { onCellValueChangedWithUpdate, onValueSetterWithValidation } from 'src/lib/3/ag-grid-util';
import {
	deletePlayLecture,
	setPreviewToken,
	updateLectureRowsOrderWithBatch,
	updatePlayLecture,
} from 'src/lib/3/firebase-short-cut';
import ToggleCell from 'src/components/Common/ToggleCell/ToggleCell';
import DeleteRowButton from 'src/components/Common/DeleteRowButton/DeleteRowButton';
import { useAuth } from 'src/stores/auth-context';
import { cloneDeep } from 'lodash-es';
import { Button } from 'antd';

type ClassroomTableProps = {
	rowData: PlayLectureDoc[];
};

const defaultColDef = {
	flex: 1,
	resizable: true,
};

const goToLecturePreview = async (lectureId: string) => {
	const token = await setPreviewToken();
	const url = 'https://englishforeveryone.netlify.app/preview';
	window.open(`${url}/${lectureId}?token=${token}`, '_blank');
};

const ClassroomTable: FC<ClassroomTableProps> = ({ rowData }) => {
	const [gridRef, setGridRef] = useState<AgGridReact<PlayLectureDoc> | null>(null);
	const { setLoading } = useAuth();

	const columnDefs: (ColDef<PlayLectureDoc> | ColGroupDef<PlayLectureDoc>)[] = useMemo(
		() => [
			{
				headerName: '순서',
				field: 'number',
				minWidth: 100,
				maxWidth: 100,
				editable: true,
				rowDrag: true,
				valueSetter: (params) =>
					onValueSetterWithValidation(params, ['_id'], undefined, {
						type: 'number',
					}),
				onCellValueChanged: (params) => onCellValueChangedWithUpdate(params, 'playLecture', params.data._id, 'number'),
			},
			{
				headerName: '강의 제목',
				field: 'name',
				minWidth: 240,
				editable: true,
				valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
				onCellValueChanged: (params) => onCellValueChangedWithUpdate(params, 'playLecture', params.data._id, 'name'),
			},
			{
				headerName: '강의 주소',
				field: 'embedUrl',
				minWidth: 200,
				editable: true,
				valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
				onCellValueChanged: (params) =>
					onCellValueChangedWithUpdate(params, 'playLecture', params.data._id, 'embedUrl'),
			},
			{ headerName: '강의 설명', field: 'description', minWidth: 200 },
			{
				headerName: '미리보기',
				field: '_id',
				minWidth: 100,
				maxWidth: 100,
				cellRenderer: (params: any) => (
					<Button icon={<LinkOutlined />} onClick={() => goToLecturePreview(params.value)} />
				),
			},
			{
				headerName: '공개',
				field: 'hide',
				minWidth: 120,
				maxWidth: 120,
				cellRenderer: (params: any) => (
					<ToggleCell
						value={!params.value}
						onChange={async (value: boolean) => {
							return updatePlayLecture(params.data._id, { hide: !value });
						}}
					/>
				),
			},
			{ headerName: 'lectureId', field: '_id', minWidth: 200, hide: true },
			{
				headerName: '',
				field: '_id',
				minWidth: 100,
				maxWidth: 100,
				cellRenderer: (params: any) => <DeleteRowButton onClick={async () => deletePlayLecture(params.value)} />,
			},
		],
		[]
	);

	const onRowDragEnd = async () => {
		const nodes = gridRef?.api.getRenderedNodes();
		const changedNodes = cloneDeep(nodes)?.filter((node) => {
			const { rowIndex, data } = node;
			return rowIndex !== null && rowIndex + 1 !== data?.number;
		});
		const updateOrders = changedNodes
			?.filter((node) => node.rowIndex !== null && node.data?._id)
			.map((node) => ({ number: node.rowIndex! + 1, id: node.data!._id }));
		if (updateOrders && updateOrders?.length > 0) {
			setLoading(true);
			await updateLectureRowsOrderWithBatch(updateOrders);
			setLoading(false);
		}
	};

	return (
		<div className="ag-theme-alpine height100">
			<AgGridReact
				ref={setGridRef}
				rowData={rowData}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				animateRows={true}
				// 편집 완료후 스크롤 이동을 막는다.
				suppressScrollOnNewData={true}
				rowDragManaged={true}
				rowDragMultiRow={true}
				rowSelection={'multiple'}
				onRowDragEnd={onRowDragEnd}
				suppressMoveWhenRowDragging={true}
			/>
		</div>
	);
};

export default ClassroomTable;
