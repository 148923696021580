import { FC, useEffect, useState } from 'react';
import { observeProduct } from 'src/lib/3/firebase-short-cut';
import { ProductDoc } from 'src/schema/schema-product';
import PaymentProductTable from './PaymentProductTable';
import CreateProductButton from './CreateProductButton';

const PaymentProduct: FC = () => {
	const [rowData, setRowData] = useState<ProductDoc[]>([]);

	useEffect(() => {
		const subscription = observeProduct().subscribe((products) => {
			const filteredProducts = products.filter((product) => !product._timeDelete);
			setRowData(filteredProducts);
		});
		return () => subscription.unsubscribe();
	}, []);
	return (
		<div className="height100 flexColumn">
			<div style={{ display: 'flex', marginBottom: 8 }}>
				<CreateProductButton />
			</div>
			<PaymentProductTable rowData={rowData} />
		</div>
	);
};

export default PaymentProduct;
