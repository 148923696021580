export const requiredRule = [{ required: true, message: '필수 항목입니다!' }];

export const playLevelMappings = {
	primer: 'primer',
	beginner: 'beginner',
	challenger: 'challenger',
	flyer: 'flyer',
	disciple: 'disciple',
	etc: 'etc',
};

export const tossPaymentsClientKey = process.env.REACT_APP_TOSS_PAYMENTS_CLIENT_KEY;
export const tossPaymentsSecretKey = process.env.REACT_APP_TOSS_PAYMENTS_SECRET_KEY;

export const tossPaymentsTransactionStatusKr: Record<string, string> = {
	READY: '🟡 결제시도',
	IN_PROGRESS: '🟡 결제중',
	WAITING_FOR_DEPOSIT: '🟡 입금대기(가상계좌)',
	DONE: '🟢 완료',
	CANCELED: '❌ 취소',
	PARTIAL_CANCELED: '⚪️ 부분취소',
	ABORTED: '❌ 승인실패',
	EXPIRED: '❌ 거래취소(만료)',
};
