import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';

import { ColDef, ColGroupDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo, useState } from 'react';
import { PlayCourseDoc } from 'src/lib/1/schema';
import { onCellValueChangedWithUpdate, onValueSetterWithValidation, timestampFormatter } from 'src/lib/3/ag-grid-util';
import { deleteCourse, updateCourseRowsOrderWithBatch } from 'src/lib/3/firebase-short-cut';
import ClassroomSelector from './ClassroomSelector/ClassroomSelector';
import { cloneDeep } from 'lodash-es';
import { useAuth } from 'src/stores/auth-context';
import DeleteRowButton from 'src/components/Common/DeleteRowButton/DeleteRowButton';

type CourseTableProps = {
	rowData: PlayCourseDoc[];
};

const defaultColDef = {
	flex: 1,
	resizable: true,
};

const CourseTable: FC<CourseTableProps> = ({ rowData }) => {
	const [gridRef, setGridRef] = useState<AgGridReact<PlayCourseDoc> | null>(null);
	const { setLoading } = useAuth();

	const columnDefs: (ColDef<PlayCourseDoc> | ColGroupDef<PlayCourseDoc>)[] = useMemo(
		() => [
			{
				headerName: '순서',
				field: 'number',
				minWidth: 100,
				maxWidth: 100,
				editable: true,
				rowDrag: true,
				valueSetter: (params) =>
					onValueSetterWithValidation(params, ['_id'], undefined, {
						type: 'number',
					}),
				onCellValueChanged: (params) => onCellValueChangedWithUpdate(params, 'playCourse', params.data._id, 'number'),
			},
			{ headerName: 'courseId', field: '_id', minWidth: 200, hide: true },
			{
				headerName: '생성시각',
				field: '_timeCreate',
				maxWidth: 200,
				valueFormatter: timestampFormatter,
				hide: true,
			},
			{
				headerName: '수정시각',
				field: '_timeUpdate',
				maxWidth: 200,
				valueFormatter: timestampFormatter,
				hide: true,
			},
			{
				headerName: 'id',
				field: 'id',
				minWidth: 100,
				maxWidth: 220,
				editable: true,
				valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
				onCellValueChanged: (params) => onCellValueChangedWithUpdate(params, 'playCourse', params.data._id, 'id'),
			},
			{
				headerName: '비밀번호',
				field: 'password',
				minWidth: 100,
				maxWidth: 160,
				editable: true,
				valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
				onCellValueChanged: (params) => onCellValueChangedWithUpdate(params, 'playCourse', params.data._id, 'password'),
			},
			{
				headerName: '제목',
				field: 'name',
				minWidth: 160,
				maxWidth: 280,
				editable: true,
				valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
				onCellValueChanged: (params) => onCellValueChangedWithUpdate(params, 'playCourse', params.data._id, 'name'),
			},
			{
				headerName: '적용 강의',
				field: 'classrooms',
				minWidth: 200,
				editable: true,
				cellRenderer: ClassroomSelector,
			},
			{
				headerName: '',
				field: '_id',
				minWidth: 100,
				maxWidth: 100,
				cellRenderer: (params: any) => <DeleteRowButton onClick={async () => deleteCourse(params.value)} />,
			},
		],
		[]
	);

	const onRowDragEnd = async () => {
		const nodes = gridRef?.api.getRenderedNodes();
		const changedNodes = cloneDeep(nodes)?.filter((node) => {
			const { rowIndex, data } = node;
			return rowIndex !== null && rowIndex + 1 !== data?.number;
		});
		const updateOrders = changedNodes
			?.filter((node) => node.rowIndex !== null && node.data?._id)
			.map((node) => ({ number: node.rowIndex! + 1, id: node.data!._id }));
		if (updateOrders && updateOrders?.length > 0) {
			setLoading(true);
			await updateCourseRowsOrderWithBatch(updateOrders);
			setLoading(false);
		}
	};

	return (
		<div className="ag-theme-alpine height100">
			<AgGridReact
				ref={setGridRef}
				rowData={rowData}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				animateRows={true}
				// 편집 완료후 스크롤 이동을 막는다.
				suppressScrollOnNewData={true}
				rowDragManaged={true}
				rowDragMultiRow={true}
				rowSelection={'multiple'}
				onRowDragEnd={onRowDragEnd}
				suppressMoveWhenRowDragging={true}
			/>
		</div>
	);
};

export default CourseTable;
