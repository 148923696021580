import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './stores/auth-context';
import Router from './Router';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
