import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { FC, useCallback } from 'react';

import { requiredRule } from '../../lib/1/constant';

import { useAuth } from '../../stores/auth-context';
import classes from './Signin.module.scss';

// import { Link } from 'react-router-dom';

const { Item } = Form;

type SigninFormValues = {
  email: string;
  password: string;
};

const Signin: FC<{ goToFindPassword: () => void }> = ({ goToFindPassword }) => {
  const { signinWithEmail, loading, setLoading, authUser } = useAuth();

  const onFinish = useCallback(
    async (values: SigninFormValues) => {
      const { email, password } = values;

      try {
        await signinWithEmail(email, password);
      } catch (error: any) {
        console.error(error);
        if (error.code === 'auth/too-many-requests') {
          message.error(
            '연속된 실패로 인해 이용이 제한되었습니다. 잠시후 다시 시도해주세요.'
          );
        } else if (error.code === 'auth/user-not-found') {
          message.error('존재하지 않는 이메일입니다.');
        } else if (error.code === 'auth/wrong-password') {
          message.error('잘못된 비밀번호입니다.');
        } else {
          message.error(error?.message ?? '알 수 없는 에러 발생!');
        }
      }
      setLoading(false);
    },
    [setLoading, signinWithEmail]
  );

  const onFinishFailed = useCallback((errorInfo: any) => {
    message.error(errorInfo ?? '알 수 없는 에러발생');
  }, []);

  return (
    <>
      <div className={classes.signinContainer}>
        {!authUser ? (
          <Form
            id="signin"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="large"
            layout="vertical"
          >
            <Item
              name="email"
              label="이메일"
              rules={[
                ...requiredRule,
                { type: 'email', message: '이메일 형식이 올바르지 않습니다.' },
              ]}
            >
              <Input />
            </Item>
            <Item name="password" label="비밀번호" rules={requiredRule}>
              <Input.Password minLength={6} />
            </Item>

            <Item>
              <Button
                type="primary"
                icon={<UserOutlined />}
                className={classes.submitSigninButton}
                htmlType="submit"
                block
                loading={loading}
              >
                로그인
              </Button>
            </Item>
            {/* <Link to='/signup'>회원가입</Link> */}
          </Form>
        ) : (
          <div className={classes.loadingBox}>
            <LoadingOutlined />
          </div>
        )}
        <Button type="text" onClick={goToFindPassword}>
          비밀번호 찾기
        </Button>
      </div>
    </>
  );
};

export default Signin;
