import { notification, Switch } from 'antd';
import { FC, useCallback, useState } from 'react';

interface ToggleCellProps {
	value: boolean;
	onChange: (value: boolean) => Promise<string | void>;
}

const ToggleCell: FC<ToggleCellProps> = ({ value, onChange }) => {
	const [loading, setLoading] = useState(false);

	const handleChange = useCallback(
		async (value: boolean) => {
			setLoading(true);
			try {
				await onChange(value);
			} catch (error: any) {
				notification.error({
					message: '에러',
					description: error.message,
				});
			}
			setLoading(false);
		},
		[onChange]
	);

	return <Switch loading={loading} checked={value} onChange={handleChange} />;
};

export default ToggleCell;
