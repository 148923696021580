import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';

import { ColDef, ColGroupDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { SettingOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { PlayClassroomDoc } from 'src/lib/1/schema';

import classes from './ClassroomModal.module.scss';
import { onCellValueChangedWithUpdate, onValueSetterWithValidation, timestampFormatter } from 'src/lib/3/ag-grid-util';
import {
	copyClassroomWithBatch,
	deletePlayClassroom,
	subscribePlayClassroom,
	updateClassroomRowsOrderWithBatch,
	updatePlayClassroom,
} from 'src/lib/3/firebase-short-cut';
import DeleteRowButton from '../Common/DeleteRowButton/DeleteRowButton';
import ToggleCell from '../Common/ToggleCell/ToggleCell';
import { useAuth } from 'src/stores/auth-context';
import { cloneDeep } from 'lodash-es';
import CopyClassroomButton from './CopyClassroomButton/CopyClassroomButton';

const defaultColDef = {
	flex: 1,
	resizable: true,
};

const ClassroomModal: FC<{ collapsed: boolean }> = ({ collapsed }) => {
	const [gridRef, setGridRef] = useState<AgGridReact<PlayClassroomDoc> | null>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [classrooms, setClassrooms] = useState<PlayClassroomDoc[]>([]);
	const { setLoading } = useAuth();

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const columnDefs: (ColDef<PlayClassroomDoc> | ColGroupDef<PlayClassroomDoc>)[] = useMemo(
		() => [
			{
				headerName: '순서',
				field: 'number',
				minWidth: 100,
				maxWidth: 100,
				editable: true,
				rowDrag: true,
				valueSetter: (params) =>
					onValueSetterWithValidation(params, ['_id'], undefined, {
						type: 'number',
					}),
				onCellValueChanged: (params) =>
					onCellValueChangedWithUpdate(params, 'playClassroom', params.data._id, 'number'),
			},
			{
				headerName: '적용 강의 제목',
				field: 'name',
				minWidth: 200,
				editable: true,
				valueSetter: (params) => onValueSetterWithValidation(params, ['name']),
				onCellValueChanged: (params) => onCellValueChangedWithUpdate(params, 'playClassroom', params.data._id, 'name'),
			},
			{
				headerName: '공개',
				field: 'hide',
				minWidth: 120,
				maxWidth: 120,
				cellRenderer: (params: any) => (
					<ToggleCell
						value={!params.value}
						onChange={async (value: boolean) => {
							return updatePlayClassroom(params.data._id, { hide: !value });
						}}
					/>
				),
			},
			{ headerName: 'courseId', field: '_id', minWidth: 200, hide: true },
			{
				headerName: '생성시각',
				field: '_timeCreate',
				maxWidth: 200,
				hide: true,
				valueFormatter: timestampFormatter,
			},
			{
				headerName: '수정시각',
				field: '_timeUpdate',
				maxWidth: 200,
				hide: true,
				valueFormatter: timestampFormatter,
			},
			{
				headerName: '',
				field: '_id',
				minWidth: 100,
				maxWidth: 120,
				cellRenderer: (params: any) => (
					<CopyClassroomButton onClick={async () => copyClassroomWithBatch(params.value)} />
				),
			},
			{
				headerName: '',
				field: '_id',
				minWidth: 100,
				maxWidth: 120,
				cellRenderer: (params: any) => <DeleteRowButton onClick={async () => deletePlayClassroom(params.value)} />,
			},
		],
		[]
	);

	const onRowDragEnd = async () => {
		const nodes = gridRef?.api.getRenderedNodes();
		const changedNodes = cloneDeep(nodes)?.filter((node) => {
			const { rowIndex, data } = node;
			return rowIndex !== null && rowIndex + 1 !== data?.number;
		});
		const updateOrders = changedNodes
			?.filter((node) => node.rowIndex !== null && node.data?._id)
			.map((node) => ({ number: node.rowIndex! + 1, id: node.data!._id }));
		if (updateOrders && updateOrders?.length > 0) {
			setLoading(true);
			await updateClassroomRowsOrderWithBatch(updateOrders);
			setLoading(false);
		}
	};

	useEffect(() => {
		const subscription = subscribePlayClassroom.subscribe(setClassrooms);
		return () => subscription.unsubscribe();
	}, []);

	return (
		<>
			<div className={classes.menuButton}>
				{!collapsed && <span>적용 강의</span>}
				{!collapsed ? (
					<Button icon={<SettingOutlined />} onClick={showModal}>
						편집
					</Button>
				) : (
					<Button onClick={showModal} style={{ width: '100%' }}>
						<SettingOutlined />
					</Button>
				)}
			</div>
			<Modal
				title="적용 강의 - 설정"
				style={{ maxWidth: '1280px' }}
				width="100%"
				bodyStyle={{ height: 'calc(100vh - 280px)' }}
				open={isModalOpen}
				centered={true}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={[<Button onClick={handleCancel}>닫기</Button>]}
			>
				<div className="ag-theme-alpine height100">
					<AgGridReact
						ref={setGridRef}
						rowData={classrooms}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						animateRows={true}
						// 편집 완료후 스크롤 이동을 막는다.
						suppressScrollOnNewData={true}
						rowDragManaged={true}
						rowDragMultiRow={true}
						rowSelection={'multiple'}
						onRowDragEnd={onRowDragEnd}
						suppressMoveWhenRowDragging={true}
					/>
				</div>
			</Modal>
		</>
	);
};

export default ClassroomModal;
