import { Button, Input, notification } from 'antd';
import { FC, useCallback, useState } from 'react';
import { createPlayClassroom } from 'src/lib/3/firebase-short-cut';

const AddClassroomButton: FC = () => {
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState<string>('');

	const onClick = useCallback(async () => {
		setLoading(true);
		if (value.trim().length > 0) {
			await createPlayClassroom(value.trim());
		} else {
			notification.error({
				message: '적용 강의 제목을 입력해주세요.',
			});
		}
		setLoading(false);
	}, [value]);

	return (
		<Input.Group compact style={{ display: 'flex', paddingBottom: 100 }}>
			<Input value={value} onChange={(e) => setValue(e.currentTarget.value ?? '')} placeholder="적용 강의 제목" />
			<Button loading={loading} onClick={onClick}>
				생성
			</Button>
		</Input.Group>
	);
};

export default AddClassroomButton;
