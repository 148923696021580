import { FC } from 'react';
import Course from 'src/components/Course/Course';

import { useTitle } from '../hooks/useTitle';

const CoursePage: FC<{ title: string }> = ({title}) => {
  useTitle(`Play NNY 관리자 | ${title}`);
  return <Course />;
};

export default CoursePage;
