import { v4 } from 'uuid';
import { message, notification } from 'antd';
import { arrayRemove, arrayUnion, Timestamp } from 'firebase/firestore';
import { map } from 'rxjs';
import { PlayClassroom, PlayClassroomDoc, PlayCourse, PlayCourseDoc, PlayLecture, PlayLectureDoc } from '../1/schema';
import { FirebaseManager } from '../2/firebase-manager';
import { dateFormat01 } from '../1/util';
import { PreviewToken } from '../2/schema-config';
import { add } from 'date-fns';
import { TossOrderDoc } from 'src/schema/schema-toss-order';
import { Product, ProductDoc } from 'src/schema/schema-product';

const firebaseManager = FirebaseManager.getInstance();
const playCoursePath = 'playCourse';
const playClassroomPath = 'playClassroom';
const playLecturePath = 'playLecture';
const previewTokenPath = 'previewToken';

export const subscribePlayCourse = firebaseManager
	.observeCollection<PlayCourseDoc>(playCoursePath, [])
	.pipe(map((docs) => docs.filter((doc) => !doc._timeDelete).sort((a, b) => a.number - b.number)));

export const subscribePlayClassroom = firebaseManager
	.observeCollection<PlayClassroomDoc>(playClassroomPath, [])
	.pipe(map((docs) => docs.filter((doc) => !doc._timeDelete).sort((a, b) => a.number - b.number)));

export const subscribePlayLectureForClassrooom = (id: string) =>
	firebaseManager
		.observeCollection<PlayLectureDoc>(playLecturePath, [['classroomId', '==', id]], {
			sortKey: 'number',
			orderBy: 'asc',
		})
		.pipe(map((docs) => docs.filter((doc) => !doc._timeDelete).sort((a, b) => a.number - b.number)));

export const createPlayCourse = async (playCourse: PlayCourse) => {
	try {
		await firebaseManager.createDoc(playCoursePath, undefined, playCourse);
	} catch (error: any) {
		notification.error({
			message: '강좌 생성 실패',
			description: error.message,
		});
	}
};

export const updateClassroomLectureList = async (classroomId: string, lectureId: string, remove?: boolean) => {
	try {
		await firebaseManager.updateDoc(playClassroomPath, classroomId, {
			lectureList: remove ? arrayRemove(lectureId) : arrayUnion(lectureId),
		});
	} catch (error: any) {
		notification.error({
			message: '강의실 배정 실패',
			description: error.message,
		});
	}
};

export const updateCourseClassroomList = async (courseId: string, classroomList: string[]) => {
	try {
		await firebaseManager.updateDoc(playCoursePath, courseId, {
			classroomList,
		});
	} catch (error: any) {
		notification.error({
			message: '강의실 배정 실패',
			description: error.message,
		});
	}
};

export const updateCourseClassrooms = async (courseId: string, classrooms: PlayCourse['classrooms']) => {
	try {
		await firebaseManager.updateDoc(playCoursePath, courseId, {
			classrooms,
		});
	} catch (error: any) {
		notification.error({
			message: '강의실 배정 실패',
			description: error.message,
		});
	}
};

export const createPlayClassroomOld = async (playClassroom: PlayClassroom) => {
	try {
		await firebaseManager.createDoc(playClassroomPath, undefined, playClassroom);
	} catch (error: any) {
		notification.error({
			message: '강의실 생성 실패',
			description: error.message,
		});
	}
};

export const createPlayLecture = async (playLecture: PlayLecture) => {
	try {
		await firebaseManager.createDoc(playLecturePath, undefined, playLecture);
	} catch (error: any) {
		notification.error({
			message: '강의 생성 실패',
			description: error.message,
		});
	}
};

export const deleteCourse = async (courseId: string) => {
	try {
		await firebaseManager.updateDoc(playCoursePath, courseId, {
			_timeDelete: Timestamp.now(),
			number: 0,
		});
		message.success('삭제 완료');
	} catch (error: any) {
		notification.error({
			message: '강의 삭제 실패',
			description: error.message,
		});
	}
};

export const deletePlayClassroom = (classroomId: string) => {
	try {
		firebaseManager.updateDoc(playClassroomPath, classroomId, {
			_timeDelete: Timestamp.now(),
			number: 0,
		});
		message.success('삭제 완료');
	} catch (error: any) {
		notification.error({
			message: '적용 강의 삭제 실패',
			description: error.message,
		});
	}
};

export const deletePlayLecture = async (lectureId: string) => {
	try {
		await firebaseManager.updateDoc(playLecturePath, lectureId, {
			_timeDelete: Timestamp.now(),
			number: 0,
		});
		message.success('삭제 완료');
	} catch (error: any) {
		notification.error({
			message: '강의 삭제 실패',
			description: error.message,
		});
	}
};

export const switchCourseNumber = async (from: PlayCourseDoc, to: PlayCourseDoc) => {
	try {
		firebaseManager.batchStart();
		await firebaseManager.updateDoc(
			playCoursePath,
			from._id,
			{
				number: to.number,
			},
			{
				bBatch: true,
			}
		);
		await firebaseManager.updateDoc(
			playCoursePath,
			to._id,
			{
				number: from.number,
			},
			{
				bBatch: true,
			}
		);
		await firebaseManager.batchEnd();
	} catch (error: any) {
		notification.error({
			message: '순서 변경 실패',
			description: error.message,
		});
	}
};

export const switchClassroomNumber = async (from: PlayClassroomDoc, to: PlayClassroomDoc) => {
	try {
		firebaseManager.batchStart();
		await firebaseManager.updateDoc(
			playClassroomPath,
			from._id,
			{
				number: to.number,
			},
			{
				bBatch: true,
			}
		);
		await firebaseManager.updateDoc(
			playClassroomPath,
			to._id,
			{
				number: from.number,
			},
			{
				bBatch: true,
			}
		);
		await firebaseManager.batchEnd();
	} catch (error: any) {
		notification.error({
			message: '순서 변경 실패',
			description: error.message,
		});
	}
};

export const updatePlayClassroom = async (classroomId: string, playClassroom: Partial<PlayClassroom>) =>
	firebaseManager.updateDoc(playClassroomPath, classroomId, playClassroom);

export const createPlayClassroom = async (name: string) => {
	try {
		const lastClassroom = await firebaseManager.getDocsArrayWithWhere<PlayClassroom>(playClassroomPath, [], {
			sortKey: 'number',
			orderBy: 'desc',
			limit: 1,
		});
		const number = lastClassroom && lastClassroom.length > 0 ? lastClassroom[0].number + 1 : 1;
		const playClassroom: PlayClassroom = {
			name,
			number,
			hide: false,
		};
		const id = await firebaseManager.createDoc(playClassroomPath, undefined, playClassroom);
		notification.success({
			message: `${name} 생성완료.`,
		});
		return id;
	} catch (error: any) {
		notification.error({
			message: '적용 강의 생성 실패',
			description: error.message,
		});
	}
	return false;
};

export const getLastPlayLectureNumberForClassroom = async (id: string) => {
	try {
		const lastLecture = await firebaseManager.getDocsArrayWithWhere<PlayLectureDoc>(
			playLecturePath,
			[['classroomId', '==', id]],
			{
				sortKey: 'number',
				orderBy: 'desc',
				limit: 1,
			}
		);
		const number = lastLecture && lastLecture.length > 0 ? lastLecture[0].number + 1 : 1;
		return number;
	} catch (error: any) {
		notification.error({
			message: '강의 순서 생성 실패',
			description: error.message,
		});
	}
	return 999;
};

export const updatePlayLecture = async (lectureId: string, playLecture: Partial<PlayLecture>) =>
	firebaseManager.updateDoc(playLecturePath, lectureId, playLecture);

export const switchLectureNumber = async (from: PlayLectureDoc, to: PlayLectureDoc) => {
	try {
		firebaseManager.batchStart();
		await firebaseManager.updateDoc(
			playLecturePath,
			from._id,
			{
				number: to.number,
			},
			{
				bBatch: true,
			}
		);
		await firebaseManager.updateDoc(
			playLecturePath,
			to._id,
			{
				number: from.number,
			},
			{
				bBatch: true,
			}
		);
		await firebaseManager.batchEnd();
	} catch (error: any) {
		notification.error({
			message: '순서 변경 실패',
			description: error.message,
		});
	}
};

type OrderChangeRowInfo = {
	number: number;
	id: string;
};
export const updateClassroomRowsOrderWithBatch = async (data: OrderChangeRowInfo[]) => {
	try {
		firebaseManager.batchStart();
		for (const row of data) {
			await firebaseManager.updateDoc(
				playClassroomPath,
				row.id,
				{
					number: row.number,
				},
				{
					bBatch: true,
				}
			);
		}
		await firebaseManager.batchEnd();
	} catch (error: any) {
		notification.error({
			message: '순서 변경 실패',
			description: error.message,
		});
	}
};

export const updateLectureRowsOrderWithBatch = async (data: OrderChangeRowInfo[]) => {
	try {
		firebaseManager.batchStart();
		for (const row of data) {
			await firebaseManager.updateDoc(
				playLecturePath,
				row.id,
				{
					number: row.number,
				},
				{
					bBatch: true,
				}
			);
		}
		await firebaseManager.batchEnd();
	} catch (error: any) {
		notification.error({
			message: '순서 변경 실패',
			description: error.message,
		});
	}
};

export const updateCourseRowsOrderWithBatch = async (data: OrderChangeRowInfo[]) => {
	try {
		firebaseManager.batchStart();
		for (const row of data) {
			await firebaseManager.updateDoc(
				playCoursePath,
				row.id,
				{
					number: row.number,
				},
				{
					bBatch: true,
				}
			);
		}
		await firebaseManager.batchEnd();
	} catch (error: any) {
		notification.error({
			message: '순서 변경 실패',
			description: error.message,
		});
	}
};

export const copyClassroomWithBatch = async (targetId: string) => {
	try {
		const classroom = await firebaseManager.getDoc<PlayClassroomDoc>(`${playClassroomPath}/${targetId}`);
		if (!classroom) {
			notification.error({
				message: '복사 실패',
				description: '적용 강의를 찾을 수 없습니다.',
			});
			return;
		}
		const lecturesForClassroom = await firebaseManager.getDocsArrayWithWhere<PlayLectureDoc>(
			playLecturePath,
			[['classroomId', '==', targetId]],
			{
				sortKey: 'number',
				orderBy: 'asc',
			}
		);
		const validLectures = lecturesForClassroom.filter((doc) => !doc._timeDelete).sort((a, b) => a.number - b.number);
		if (validLectures.length === 0) {
			notification.warning({
				message: '복사 취소',
				description: '복사할 강의가 없습니다.',
			});
			return;
		}
		const classroomId = await createPlayClassroom(classroom.name + ' (복사본)');
		if (!classroomId) {
			notification.error({
				message: '복사 실패',
				description: '적용 강의 생성에 실패했습니다.',
			});
			return;
		}

		firebaseManager.batchStart();
		for (const lecture of validLectures) {
			const newLecture: PlayLecture = {
				...lecture,
				classroomId,
			};
			await firebaseManager.createDoc(playLecturePath, undefined, newLecture, {
				bBatch: true,
			});
		}
		await firebaseManager.batchEnd();
	} catch (error: any) {
		notification.error({
			message: '복사 실패',
			description: error.message,
		});
	}
};

/**
 * 미리보기를 위한 임시 토큰을 발행한다.
 */
export const setPreviewToken = async (service = 'play-nny') => {
	const token = v4();
	const date = add(new Date(), { minutes: 3 });
	const expiredAt = dateFormat01(date);
	const previewToken: PreviewToken = {
		token,
		expiredAt,
	};
	try {
		await firebaseManager.setDoc(previewTokenPath, service, previewToken);
	} catch (error: any) {
		notification.error({
			message: '미리보기 토큰 발행 실패',
			description: error.message,
		});
	}
	return token;
};

// export const createVimeoConf = async () => {
//   try {
//     for (const lecture of temp) {
//       const playLecture: PlayLecture = {
//         vimeoUrl: lecture.uri,
//         embedUrl: lecture.player_embed_url,
//         name: lecture.name,
//         description: lecture.description,
//         videoCreatedAt: lecture.created_time,
//       };
//       await firebaseManager.createDoc('playLecture', undefined, playLecture);
//     }
//   } catch (error: any) {
//     notification.error({
//       message: '데이터 실패',
//       description: error.message,
//     });
//   }
// };

// tossOrder
const tossOrderPath = 'tossOrder';
export const getTossOrder = async (id: string) => firebaseManager.getDoc<TossOrderDoc>(`${tossOrderPath}/${id}`);

// product
const productPath = 'product';
export const observeProduct = () => firebaseManager.observeCollection<ProductDoc>(productPath, []);
export const createProduct = async (product: Product) => firebaseManager.createDoc(productPath, undefined, product);
export const deleteProduct = async (productId: string) =>
	firebaseManager.updateDoc(productPath, productId, {
		_timeDelete: Timestamp.now(),
	});
