import { Button, Form, Input, Modal } from 'antd';
import { FC, useEffect, useState } from 'react';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { PlayCourse, PlayCourseDoc } from 'src/lib/1/schema';
import { FirebaseManager } from 'src/lib/2/firebase-manager';
import { createPlayCourse } from 'src/lib/3/firebase-short-cut';
import { useAuth } from 'src/stores/auth-context';

const firebaseManager = FirebaseManager.getInstance();
const playCoursePath = 'playCourse';

const CourseForm: FC = () => {
	const isDesktop = useMediaQuery();
	const { setLoading } = useAuth();
	const [open, setOpen] = useState(false);
	const [form] = Form.useForm<PlayCourse>();

	const onFinish = async (values: PlayCourse) => {
		setLoading(true);
		const currentCourses = await firebaseManager.getDocsArrayWithWhere<PlayCourseDoc>(playCoursePath, [], {
			sortKey: 'number',
			orderBy: 'desc',
			limit: 1,
		});
		const number = currentCourses && currentCourses.length > 0 ? currentCourses[0].number + 1 : 1;
		await createPlayCourse({
			id: values.id.trim(),
			password: values.password.trim(),
			name: values.name.trim(),
			classrooms: [],
			number,
		});
		setLoading(false);
	};

	const FormBox = (
		<Form
			form={form}
			name="horizontal_login"
			layout={isDesktop ? 'inline' : 'horizontal'}
			onFinish={onFinish}
			style={{ marginBottom: 20 }}
		>
			<Form.Item
				name="id"
				label={isDesktop ? undefined : 'id'}
				rules={[{ required: true, message: 'id를 입력해주세요.' }]}
			>
				<Input placeholder="id" />
			</Form.Item>
			<Form.Item
				name="password"
				label={isDesktop ? undefined : '비밀번호'}
				rules={[{ required: true, message: '비밀번호를 입력해주세요.' }]}
			>
				<Input placeholder="비밀번호" />
			</Form.Item>
			<Form.Item
				name="name"
				label={isDesktop ? undefined : '제목'}
				rules={[{ required: true, message: '제목을 입력해주세요.' }]}
			>
				<Input placeholder="제목" />
			</Form.Item>
			<Form.Item shouldUpdate>
				{() => (
					<Button type="primary" htmlType="submit" onClick={() => setOpen(false)}>
						생성
					</Button>
				)}
			</Form.Item>
		</Form>
	);

	useEffect(() => {
		if (!open) form.resetFields();
	}, [form, open]);

	return (
		<>
			{isDesktop ? (
				FormBox
			) : (
				<>
					<Button style={{ marginBottom: 10 }} onClick={() => setOpen(true)}>
						계정 생성
					</Button>
					<Modal title="계정 생성" open={open} footer={null} onCancel={() => setOpen(false)}>
						{FormBox}
					</Modal>
				</>
			)}
		</>
	);
};

export default CourseForm;
