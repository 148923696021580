import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC, useState } from 'react';

interface CopyClassroomButtonProps {
	onClick: () => Promise<void>;
}

const CopyClassroomButton: FC<CopyClassroomButtonProps> = ({ onClick }) => {
	const [loading, setLoading] = useState(false);

	const handleOnClick = async () => {
		setLoading(true);
		try {
			await onClick();
		} catch (error) {
			console.error(error);
		}
		setLoading(false);
	};
	return (
		<Button icon={<CopyOutlined />} type="text" loading={loading} onClick={handleOnClick}>
			복제
		</Button>
	);
};

export default CopyClassroomButton;
