import { FC, useState } from 'react';

import { useTitle } from '../hooks/useTitle';
import Signin from '../components/Signin/Signin';
import FindPassword from '../components/FindPassword/FindPassword';

const SigninPage: FC<{ title: string }> = ({title}) => {
	useTitle(`Play NNY 관리자 | ${title}`);
	const [findPassword, setFindPassword] = useState(false);

	return findPassword ? (
		<FindPassword backToSignin={() => setFindPassword(false)} />
	) : (
		<Signin goToFindPassword={() => setFindPassword(true)} />
	);
};

export default SigninPage;
