import { EyeInvisibleOutlined } from '@ant-design/icons';
import { Layout, Menu, MenuProps } from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ItemType } from 'antd/lib/menu/hooks/useItems';

import Logo from 'src/assets/images/play-nny.svg';

import { siteMap } from '../../Router';
import classes from './MainNavigation.module.scss';
import { PlayClassroomDoc } from 'src/lib/1/schema';
import ClassroomModal from '../ClassroomModal/ClassroomModal';
import { subscribePlayClassroom } from 'src/lib/3/firebase-short-cut';
import AddClassroomButton from '../Common/AddClassroomButton/AddClassroomButton';

const { Sider } = Layout;

/** xs: '480px', sm: '576px', md: '768px', lg: '992px', xl: '1200px', xxl: '1600px', */
const breakpoint = 'md';

const MainNavigation: FC = () => {
	const [collapsed, setCollapsed] = useState(false);
	const [classrooms, setClassrooms] = useState<PlayClassroomDoc[]>([]);
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const items: ItemType[] = useMemo(() => {
		const classroomItems = {
			label: <ClassroomModal collapsed={collapsed} />,
			key: 'classroom',
			type: 'group',
			children: [
				...classrooms.map((classroom) => ({
					label: (
						<span>
							{classroom.hide ? <EyeInvisibleOutlined /> : ''} {classroom.name}
						</span>
					),
					style: { color: classroom.hide ? 'gray' : 'black' },
					key: `classroom/${classroom._id}`,
				})),
				{ label: <AddClassroomButton />, type: 'group' },
			],
		};
		return [
			...Object.values(siteMap)
				.filter((page) => page.icon !== undefined)
				.map(({ title, icon, path, children }) => ({
					label: title,
					icon,
					key: path,
					children,
				})),
			classroomItems,
		];
	}, [classrooms, collapsed]);

	const onClickMenu: MenuProps['onClick'] = useCallback(
		(e: { key: string }) => {
			navigate(e.key);
		},
		[navigate]
	);

	useEffect(() => {
		const subscription = subscribePlayClassroom.subscribe(setClassrooms);
		return () => subscription.unsubscribe();
	}, []);

	return (
		<Sider
			theme="light"
			width={280}
			style={{ overflow: 'scroll' }}
			breakpoint={breakpoint}
			collapsible
			collapsed={collapsed}
			onCollapse={setCollapsed}
		>
			<Link className={classes.logo} to="/">
				{collapsed ? <img alt="logo" src={Logo} /> : <span>Play NNY</span>}
			</Link>
			<Menu
				mode="inline"
				selectedKeys={[pathname.substring(1)]}
				defaultOpenKeys={['classroom']}
				items={items}
				onClick={onClickMenu}
			/>
		</Sider>
	);
};

export default MainNavigation;
