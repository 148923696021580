import { add, format, sub } from 'date-fns';
import { ko } from 'date-fns/locale';
import dayjs, { Dayjs } from 'dayjs';

export const getCurrentDateWithoutTime = () => {
	const nowDate = new Date();
	return new Date(nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate());
};

type DateForamt =
	| 'yyyy-MM-dd'
	| "yyyy-MM-dd'T'HH:mm:ss+0900"
	| 'L월 d일(EEEEEE) HH:mm:ss'
	| 'yyyy-MM-dd HH:mm:ss'
	| 'yyyy. M. d'
	| 'L월 d일';
export const formatDate = (date: Date, dateFormat: DateForamt, locale = ko) => format(date, dateFormat, { locale });

/**
 * @param orderDate '2018-02-06T20:00:00+0400'
 * @returns '2018-02-06 20:00:00'
 */
export const orderDateFormat02 = (orderDate: string) =>
	format(new Date(orderDate), 'yyyy-MM-dd HH:mm:ss', { locale: ko });

export type RangeEventValue = [Dayjs | null, Dayjs | null] | null;
export const getDefaultDates = (days = 0, to = 7): [Dayjs, Dayjs] => {
	const now = dayjs(getCurrentDateWithoutTime());
	const right = dayjs(now).add(to, 'day');
	const left = dayjs(now).subtract(days, 'day');
	return [left, right];
};

export const dayjsesToDateWhere = (left: Dayjs, right: Dayjs, dayMargin?: number): [Date, Date] => {
	const leftDate = dayMargin ? sub(left.toDate(), { days: dayMargin }) : left.toDate();
	const rightDate = dayMargin ? add(right.toDate(), { days: 1 }) : right.toDate();
	return [leftDate, rightDate];
};

/**
 * @returns ['2023-07-01T00:00:00', '2023-07-08T23:59:59']
 */
export const dayjsesToTossPaymentsDates = (left: Dayjs, right: Dayjs): [string, string] => {
	const leftDate = format(new Date(left.toDate()), "yyyy-MM-dd'T'00:00:00");
	const rightDate = format(new Date(right.toDate()), "yyyy-MM-dd'T'23:59:59");
	return [leftDate, rightDate];
};
