import { FC, useEffect, useState } from 'react';
import { PlayCourseDoc } from 'src/lib/1/schema';
import { subscribePlayCourse } from 'src/lib/3/firebase-short-cut';
import CourseForm from './CourseForm/CourseForm';
import CourseTable from './CourseTable/CourseTable';

const Course: FC = () => {
	const [rowData, setRowData] = useState<PlayCourseDoc[]>([]);

	useEffect(() => {
		const subscription = subscribePlayCourse.subscribe((docs) => {
			setRowData(docs);
		});

		return () => {
			subscription.unsubscribe();
		};
	}, []);

	return (
		<div className="height100 flexColumn">
			<CourseForm />
			<CourseTable rowData={rowData} />
		</div>
	);
};

export default Course;
