import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import Classroom from 'src/components/Classroom/Classroom';

import { useTitle } from '../hooks/useTitle';

const ClassroomPage: FC<{ title: string }> = ({ title }) => {
	useTitle(`Play NNY 관리자 | ${title}`);
	const { classroomId } = useParams();

	return classroomId ? <Classroom classroomId={classroomId} /> : <Navigate to="/" replace />;
};

export default ClassroomPage;
