import { Button, Popconfirm } from 'antd';
import { FC, useState } from 'react';

interface DeleteRowButtonProps {
	onClick: () => Promise<void>;
}

const DeleteRowButton: FC<DeleteRowButtonProps> = ({ onClick }) => {
	const [loading, setLoading] = useState(false);

	const handleOnConfirm = async () => {
		setLoading(true);
		try {
			await onClick();
		} catch (error) {
			console.error(error);
		}
		setLoading(false);
	};
	return (
		<Popconfirm title="정말로 삭제하시겠습니까?" onConfirm={handleOnConfirm} okButtonProps={{ loading }}>
			<Button type="text" danger>
				삭제
			</Button>
		</Popconfirm>
	);
};

export default DeleteRowButton;
